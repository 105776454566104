<template>
<div class="impressum">
<h1>
Impressum
</h1>

        <h3>Verantwortlich für den Inhalt: </h3>
        <p>
            Ali Gürbey<br>
            Sophiengutstraße 22<br>
            4020 Linz<br>
            Austria
        </p>

        <h3>Kontakt:</h3>
         <p>Siehe Startseite.</p>

         <h3>Haftungsausschluss:</h3>
        <p>Der Inhalt dieser Webseite wurde mit größter Sorgfalt erstellt. Dennoch kann ich keine Gewähr für die
            Richtigkeit, Vollständigkeit und Aktualität der bereitgestellten Informationen übernehmen.
            Als Anbieter bin ich gemäß § 17 E-Commerce-Gesetz (ECG) für eigene Inhalte auf dieser Webseite nach den
            allgemeinen Gesetzen verantwortlich. Gemäß § 18 ECG bin ich jedoch nicht verpflichtet, übermittelte oder
            gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
            Tätigkeit hinweisen. Die Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den
            allgemeinen Gesetzen bleiben hiervon unberührt. Eine Haftung in diesem Zusammenhang ist jedoch erst ab dem
            Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
            Rechtsverletzungen werde ich diese Inhalte umgehend entfernen.</p>

        <h3>Urheberrecht:</h3>
        <p>Die durch mich als Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem
            österreichischen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
            Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen
            Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen
            Gebrauch gestattet.</p>

        <h3>Erhebung und Verarbeitung personenbezogener Daten:</h3>
        <p>Ich möchte darauf hinweisen, dass meine Webseite keine personenbezogenen Daten von Ihnen erhebt, verarbeitet
            oder nutzt. Es werden keine Informationen gesammelt, die Rückschlüsse auf Ihre Identität ermöglichen. </p>

        <h3>Cookies:</h3>
        <p>Meine Webseite verwendet keine Cookies zur Sammlung von personenbezogenen Daten oder zur Verfolgung Ihres
            Nutzungsverhaltens.</p>

        <h3>Datenübermittlung an Dritte:</h3>
        <p>Da ich keine personenbezogenen Daten erhebe, verarbeite oder nutze, findet auch keine Weitergabe oder
            Übermittlung von Daten an Dritte statt.</p>

        <h3>Externe Links:</h3>
        <p>Meine Webseite kann Links zu externen Websites enthalten. Bitte beachten Sie, dass ich keinen Einfluss auf
            den Inhalt oder die Datenschutzpraktiken dieser Websites habe. Diese Datenschutzerklärung gilt
            ausschließlich für meine Webseite. Ich empfehle Ihnen, die Datenschutzerklärungen der verlinkten Websites zu
            lesen, um Informationen über deren Umgang mit personenbezogenen Daten zu erhalten.</p>

         <h3>Icons</h3>
         <p>
            Wenn Icons von diversen Websites benutzt worden sind, dann sind die entsprechenden Links hier zu finden: <br>
            Back icons created by Roundicons - Flaticon https://www.flaticon.com/free-icons/back

         </p>

        <h3>Sicherheit:</h3>
        <p>Ich treffe angemessene technische und organisatorische Maßnahmen, um Ihre Daten vor Verlust, Missbrauch,
            unbefugtem Zugriff, Offenlegung oder Änderung zu schützen. Da jedoch keine personenbezogenen Daten von
            Ihnen erhoben werden, besteht kein Risiko für Ihre Privatsphäre oder Sicherheit im Zusammenhang mit meiner
            Webseite.</p>

        <h3>Änderungen der Datenschutzerklärung:</h3>
        <p>Ich behalte mir vor, diese Datenschutzerklärung jederzeit zu ändern. Die aktuelle Version ist auf meiner
            Webseite verfügbar.</p>




</div>

</template>


<script>
 export default{
    name:"Impressum",
 }
</script>
    


<style scoped>
.impressum{
   height: 100vh;
   width: 60vw;
   background-color: aliceblue;
   position: absolute;
   left: 20%;
   z-index: 1;
   overflow: scroll;
}

.contact{
   display: flex;
   flex-direction: column;
   height: 5vh;
}
h3{
   font-size: 3vh;
   margin-left: 2vw;
}
p{
   margin-left: 2vw
}
h1{
   margin-left: 2vw;
}
</style>