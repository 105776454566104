<template>
    <div class="arrows">
    <div class="arrowLeft"></div>
    <div class="arrowRight"></div>
    </div>
</template>



<script>

export default{
    name: "Arrows",
}
</script>


<style>

.arrows{
   
   height: 10vh;
   width: 29vw;
   display: flex;
   justify-content: space-around;
   align-items: center;
}

.arrowLeft, .arrowRight {
   width: 0;
 height: 0;
 border-top: 20px solid transparent;  /* Höhe des Dreiecks anpassen */
 border-bottom: 20px solid transparent;  /* Höhe des Dreiecks anpassen */
 border-right: 34.6px solid  #dbdee0;
 ;  /* 34.6px entspricht der Höhe des Dreiecks / sqrt(3) */
}

.arrowLeft:hover, .arrowRight:hover {
    border-right: 34.6px solid #4070f47e;
    cursor: pointer;
}
.arrowRight{
   rotate: 180deg;
}



</style>