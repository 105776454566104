<template>
    <button> 
      <slot></slot>
    </button>
    </template>
        
        <script>
        
        export default{
            name: 'Buttons',
            
        }
        </script>
        
        <style scoped>
        button {
          width: 14vw;
          position: relative;
          height: 10vh;
          border: 0.43165vh ridge #dbdee0;
          outline: none;
          background-color: transparent;
          color: white;
          transition: 1s;
          border-radius: 10%;
          font-size: 3vh;
          font-weight: bold;
          cursor: pointer;
          font-family: "Book Antiqua", sans-serif;
        }
      
        button:hover::before,
        button:hover::after {
          transform: scale(0);
        }
      
        button:hover {
          z-index: 1;
          border-color: transparent;
          background-color: #4070f47e;
          backdrop-filter: blur(10px);
        }
      </style>
      