<template>
<div id="scrollContact" class="contact">
    <h1>Contact me!</h1>
    <button @click="copyEmail">
        <span>Copy Mail</span>
        <span>Copied</span>
    </button>

    <div id="social-icons">

        <img @click="scrollToTop" src="@/icons/Logo_ohne.png" alt="">
    <a href="https://www.linkedin.com/in/ali-g%C3%BCrbey-31209a255/" target="_blank">
      <img src="@/icons/linkedIn_weiß.png" alt="LinkedIn">
    </a>
    <a href="https://www.instagram.com/grby.pictures/" target="_blank">
      <img src="@/icons/instagram_weiß.png" alt="Instagram">
    </a>

  </div>
   
</div>


</template>

<script>

export default{
    name: "Contact",

    data(){
        return{
            array: ["info","@", "ali-", "guerbey", ".", "at"],

        }
    },

    methods:{
        generateEmail(){
                return this.array[0] + this.array[1] +this.array[2] +this.array[3] + this.array[4]+ this.array[5]
        },

        copyEmail(){
            navigator.clipboard.writeText(this.generateEmail());
        }
    ,
    scrollToTop(){
        window.scrollTo({
        top: 0,
        behavior: 'smooth' // Für einen sanften Scroll-Effekt
      });
    },

    }
}


</script>   

<style scoped>
.contact{
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
   
}

h1{
    color: #dbdee0;
    position: relative;

}

button {
  background-color: #F2F7FA;
  width: 9.8958vw;
  height: 7.769vh;
  border: none;
  border-radius: 1.4vh;
  font-weight: 600;
  overflow: hidden;
  transition-duration: 700ms;
  font-size: 3vh;
}

button:hover{
    cursor: pointer;
}

button span:first-child {
  color: #0E418F;
  position: absolute;
  transform: translate(-50%, -50%)
}

button span:last-child {
  position: absolute;
  color: #B5CCF3;
  opacity: 0;
  transform: translateY(100%) translateX(-50%);
  height: 2.01vh;
  line-height: 1.87vh;
}

button:focus {
  background-color: #0E418F;
  width: 120px;
  height: 40px;
  transition-delay: 100ms;
  transition-duration: 500ms;
}

button:focus span:first-child {
  color: #B5CCF3;
  transform: translateX(-50%) translateY(-150%);
  opacity: 0;
  transition-duration: 500ms;
}

button:focus span:last-child {
  transform: translateX(-50%) translateY(-50%);
  opacity: 1;
  transition-delay: 300ms;
  transition-duration: 500ms;
}

button:focus:end {
  background-color: #FFFFFF;
  width: 17.26vh;
  height: 6vh;
  transition-duration: 900ms;
}

.centralize {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#social-icons{
    margin-top: 10vh;
    margin-left: 80vw;
}

#social-icons img {
    width: 30px;
    height: 30px;
    margin: 0 10px;
}

#social-icons :hover {
    cursor: pointer;
    transition: opacity 0.5s ease-out;
}

</style>