<template>
 <Header>
 </Header>
 <Welcome>
 </Welcome>
 <Gallery>
 </Gallery>
 <StartProject>
 </StartProject>
 <Contact>
 </Contact>
  
</template>

<script>
import Header from '@/components/Header.vue';
import Welcome from '@/components/Welcome.vue';
import Gallery from '@/components/Gallery.vue';
import StartProject from './components/StartProject.vue';
import Contact from './components/Contact.vue';
import Impressum from './components/Impressum.vue';
export default{

  components:{
  Header,
  Welcome,
  Gallery,
  StartProject,
  Contact,
  Impressum,
}
}


</script>

<style>

body{
  margin: 0;
  background-color: rgb(53, 50, 50);
  font-family: "Book Antiqua", sans-serif;
  overflow: scroll;
  overflow-x: hidden;
}
h1{
  font-size: 8vh;
}
</style>
