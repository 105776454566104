<template>
<div id="scrollProject" class="project">
    <h1>Latest Projects</h1>

    <div class="container">
        <img v-for="image in images" :src="require(`@/project_cover/${image}`)" alt="">
    </div>
   

  
</div>
</template>

<script>

import Arrows from './Arrows.vue';
export default{
    name: 'Project',

    components:{
        Arrows,
    },
    data(){
        return{
            images: ["owr.jpg","owr2.jpg","owr3.jpg","owr4.jpg","owr5.jpg","owr6.jpg","owr7.jpg","owr8.jpg","owr9.jpg","owr10.jpg","owr11.jpg"]
        };
    },
}
</script>

<style scoped>

.container{
    width: 35vw ;
    display: flex;
    overflow: auto;

}
.project{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #dbdee0;
}

img{
    width: 35vw;
    margin-bottom: 5vh;
}

img:hover{
    filter: brightness(70%);
    cursor: pointer;
}

h1{
    margin-bottom: 10vh;
}
</style>
