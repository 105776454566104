<template>
    <header>
        <Buttons @click="scrollToPictures"> Photography </Buttons>
        <Buttons @click="scrollToProjects"> Projects </Buttons>
        <img src="@/img/Logo.png" alt="">
        <Buttons @click="scrollToContact"> Contact </Buttons>
         <Buttons @click="toggleImpressum"> {{label}} </Buttons>
    </header>
    <Impressum v-show="showImpressum" @close="closeImpressum">
    </Impressum>
</template>

<script>
import Buttons from './Buttons.vue';
import Impressum from './Impressum.vue';
export default{
    name: 'Header',
    components:{
        Buttons,
        Impressum
    },
    data(){
        return{
            showImpressum: false,
            label: "Imprint",
            targetPosition: 0,
        };
    },

     methods: {
            toggleImpressum() {
      this.showImpressum = !this.showImpressum;

      if(this.showImpressum){
        this.label ="Close";
      }
      else{
        this.label="Imprint";
      }
    },

    closeImpressum() {
      this.showImpressum = false;
      
    },
    scrollToPictures() {
      const scrollTarget = document.getElementById('scrollGallery');

    if (scrollTarget) {
      scrollTarget.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
    },
    scrollToProjects() {
      const scrollTarget = document.getElementById('scrollProject');

if (scrollTarget) {
  scrollTarget.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
  });
}
    },

    scrollToContact() {
      const scrollTarget = document.getElementById('scrollContact');

    if (scrollTarget) {
      scrollTarget.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
    },

   
        },

}
</script>
<style scoped>
header{
    height: 20vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 32px;
}

img{
    width: 30vh;
}
</style>