<template>
    <div :class="[gallery, { photoGallery: !showAllPhotos }]">
      <div id="scrollGallery" class="titel">
        <h1 :class="title" >Photography Workshop</h1>
        
      </div>
  
      <div :class="[container, { none: showAllPhotos }]">
        <div class="wrapper" :images="images">
          <img v-for="image in images" :src="require(`@/img/${image}`)" alt="pictures_guerbey" class="slider-image">
        </div>
        
      </div>
      <Buttons :class="[buttons, { none: showAllPhotos }]" @click="openGallery" style="color: black;">{{ buttonText }}</Buttons>
      <div :class="[galleryContainer, { none: !showAllPhotos }]">
       
          <img v-for="image in images" :src="require(`@/img/${image}`)" alt="pictures_guerbey" class="gallery-image">  
          <Buttons class="returnButton" @click="openGallery" style="color: black;"><img src="@/icons/left-arrow.png" alt="https://www.flaticon.com/free-icons/back"></Buttons>
        </div>
    </div>
  
    
  </template>
  
  <script>
  import Buttons from './Buttons.vue';
  import Arrows from './Arrows.vue';
  
  export default {
    name: 'Gallery',

  
    components: {
      Buttons,
      Arrows
    },
  
    data() {
      return {
        showAllPhotos: false, // Starte mit false, wenn du den Abschnitt nicht anzeigen möchtest
        images: ["Bouldern_Dominik.jpg", "Bouldern_Portrait.jpg",
          "Img_Snowboard.jpg", "Langzeitbelichtung.jpg", 
          "Lena_Bouldern.jpg", "Lena_schwarzweiß.jpg", "Portrait_jana.jpg", 
          "portrait_judy_verena.jpg", "portrait_judy.jpg", "Tiefenschärfe.jpg", "portrait_verena.jpg", "Bierflaschen.jpg",],
  
        gallery: 'gallery',
        container: 'container',
        galleryContainer: 'galleryContainer',
        title: '',
        buttonText: "Show all",
        buttons: "buttons"
      };
    },
  
    methods: {
      openGallery() {
        // Hier kannst du die Logik hinzufügen, um die Bedingung zu ändern
        this.showAllPhotos = !this.showAllPhotos;
        if(this.title ===''){
            this.title = "none";
        }
        else{
            this.title='';
        }

        if(this.showAllPhotos){
            this.buttonText ="<-";
        }
        else{
            this.buttonText= "Show all";
        }
       
        
      },
    },
  };
  </script>

<style scoped>

.photoGallery {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: aliceblue;
}

.title {
  display: flex;
  flex-direction: row;
}

.gallery-image {
  width: 20vw;
}

.galleryContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-left: 19%;
}

.gallerContainer img {
  width: 10vw;
}

.none {
  display: none;
}

h1 {
  font-size: 8vh;
  color: black;
}

.titel .container {
  position: absolute;
}

.gallery {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: aliceblue;
}

.container {
  width: 45vw;
  overflow: hidden;
  position: relative;
}

.wrapper {
  width: 100%;
  display: flex;
  animation: slide 20s infinite;
  position: relative;
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(0);
  }
  30% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(-100%);
  }
  55% {
    transform: translateX(-200%);
  }
  75% {
    transform: translate(-200%);
  }
  80% {
    transform: translateX(-300%);
  }
  100% {
    transform: translateX(-300%);
  }
}

img {
  width: 100%;
}

/* Neu hinzugefügt */
.buttons {
  position: absolute;
  bottom: -99vh;
  right: 1vw;
}

.returnButton {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -99vh;
  right: 1vw;
  width: 3.5em;
}

</style>