<template>

<div class="container">
<img src="@/img/img_ali_sw.jpg" alt="portrait black/white">
<div class="textContainer">
<p> Hi... <br> My name is Ali</p>
<div class="swichText">
<p class="text firstText"> I'm a </p>
<p class="text secondText debug">{{text}}</p>
</div>
</div>
</div>

</template>

<script>

export default{
name: 'Welcome',
data(){
    return{
        text: "",
        textArray: ["Photographer", "Designer", "Frontend Developer"],
        index: 0,
    }
    
},
methods:{
    textLoad(){
        setTimeout(() => {
    this.text = this.textArray[this.index];
    this.index++;

    if (this.index < this.textArray.length) {
      // Rufe die Funktion erneut auf, wenn es noch Elemente im Array gibt
      this.textLoad();
    } else {
      // Wenn das Array durchlaufen wurde, setze den Index zurück und starte erneut
      this.index = 0;
      this.textLoad();
    }
  }, 4000);
       
    }

},
created(){
    this.textLoad();
}
}

</script>

<style scoped>


.container{
    display: flex;
    justify-content: center;
    align-items: center;

    height: 80vh;
    width: 100%;
    gap: 20px;
    
}
img{
    height: 60vh;
    width: 60vh;
    position: absolute;
    right: 50vw;
}

.textContainer{
    position: absolute;
    left: 52vw;
}

p{
    color: #dbdee0;
    font-size: 4.3vh;
}

.swichText{
    display: flex;
    gap: 1vw;
    margin-top: -5vh;
}

.swichText .text{
    position: relative;
    color: #4070F4;
    font-size: 4.3vh;
    font-weight: 600;
}
.swichText .text.firstText{
    position: relative;
    color: #fff;

}
.text.secondText:before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgb(53, 50, 50);
    border-left: 2px solid #dbdee0;
    animation: typeanim 4s steps(12) infinite;
    
}


@keyframes typeanim {
    40%, 60%{
        left: 100%;
    }
    100%{
        left: 0%;
    }
}

</style>